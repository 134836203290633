import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Box,
  Grid,
  Typography,
  ThemeProvider,
  createTheme,
  InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../database/config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth_actions";
import logoOtherColor from "../assets/logo.png";
import fondo from "../assets/fondo.svg";
import alienPass from "../assets/alienPass.svg";
import alienUser from "../assets/alienUser.svg";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (userData, userId) => {
    dispatch(login(userData, userId));
  };

  const onLogin = async (e) => {
    e.preventDefault();

    // Validación de campos vacíos
    if (!email.trim() || !password.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, rellena todos los campos.",
      });
      return;
    }

    // Mostrar modal de carga
    Swal.fire({
      imageUrl: logoOtherColor,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: "#2f2d33",
    });

    try {
      // Intentar iniciar sesión
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password.trim()
      );

      // Verificar si el correo del usuario está verificado
      if (!userCredential.user.emailVerified) {
        Swal.close();
        Swal.fire({
          icon: "warning",
          title: "Correo no verificado",
          text: "Por favor, verifica tu correo. Recuerda revisar la carpeta de spam.",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "¡Inicio de sesión exitoso!",
          text: "Bienvenido de nuevo.",
        });

        // Aquí puedes realizar acciones adicionales, como navegar o cargar datos del usuario.
        const user = userCredential.user;
        handleLogin({ email: user.email }, user.uid); // Dispatch login
        navigate("/workSpace"); // Redirige al usuario al home o a la página que desees
      }
    } catch (error) {
      Swal.close(); // Cierra el modal de carga antes de mostrar el error
      // Manejar errores específicos de Firebase
      if (error.code === "auth/wrong-password") {
        Swal.fire({
          icon: "error",
          title: "Contraseña incorrecta",
          text: "Por favor, verifica tu contraseña.",
        });
      } else if (error.code === "auth/user-not-found") {
        Swal.fire({
          icon: "error",
          title: "Usuario no encontrado",
          text: "El correo ingresado no está registrado.",
        });
      } else if (error.code === "auth/too-many-requests") {
        Swal.fire({
          icon: "error",
          title: "Demasiados intentos fallidos",
          text: "Por favor, inténtalo más tarde.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al iniciar sesión",
          text: "Ocurrió un problema inesperado. Intenta de nuevo más tarde.",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1f1f1f", // Puedes eliminar esto si el fondo de la imagen lo cubre
        height: "100vh",
        backgroundImage: `url(${fondo})`,
        backgroundSize: "contain", // Para que la imagen cubra todo el espacio
        backgroundPosition: "center", // Centra la imagen
      }}
    >
      <Box
        item
        xs={11}
        md={4}
        sx={{
          borderRadius: "25px",
          backgroundColor: "rgba(2,2,2,0.4)",
          minHeight: "45vh",
          minWidth: "30vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="form"
          noValidate
          onSubmit={onLogin}
          sx={{
            mt: 1,
            width: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="title1" fontSize={"25px"} color={"#77F8E0"}>
            Inicar sesión en tu cuenta
          </Typography>
        </Box>

        <Box
          component="form"
          noValidate
          onSubmit={onLogin}
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ mb: 2, width: "90%", }}
            margin="normal"
            required
            fullWidth
            placeholder="Correo"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={alienUser}
                    style={{
                      filter: "invert(100%)",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                padding: "3%",
              },
            }}
            inputProps={{
              style: {
                height: "90%",
                color: "white",
              },
            }}
          />

          <TextField
            sx={{ mb: 2, width: "90%" }}
            margin="normal"
            required
            fullWidth
            placeholder="Contraseña"
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={alienPass}
                    style={{
                      filter: "invert(100%)",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                padding: "3%",
              },
            }}
            inputProps={{
              style: {
                height: "90%",
                color: "white",
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              height: "48px",
              width: "90%",
              background: "#006686",
              color: "#77F8E0",
              borderRadius: "5px",
              fontSize: "20px",
              ":hover": { background: "#77F8E0", color: "#006686" },
            }}
          >
            Ingresar
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 1,
              mb: 2,
              height: "48px",
              width: "80%",
              background: "#006686",
              color: "#77F8E0",
              borderRadius: "5px",
              fontSize: "17px",
              ":hover": { background: "#77F8E0", color: "#006686" },
            }}
          >
            Registarse
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
