import "./App.css";
import { TitleProvider } from "./context/TitleContext";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Pages
import Workspace from "./pages/work_space";
import MainLayout from "./pages/main_layout";
import Register from "./pages/register";
import { Container } from "@mui/material";
import Login from "./pages/login";
import Home from "./pages/home";

function App() {
  const logged = useSelector((state) => state.auth.logged);
  const userId = useSelector((state) => state.auth.idUser);

  return (
    <TitleProvider>
      <BrowserRouter>
        <Routes>
          {/* Rutas públicas */}
          {!logged && <Route path="/register" element={<Register />} />}
          {!logged && <Route path="/login" element={<Login />} />}
          {!logged && <Route path="/" element={<Home />} />}
          {logged && (
            <Route path="/" element={<Navigate to="/workspace" replace />} />
          )}

          {/* Rutas protegidas con Layout */}
          {logged ? (
            <Route element={<MainLayout title="App Layout" />}>
              <Route
                path="/workspace"
                element={<Workspace userId={userId} />}
              />
              <Route path="/home" element={<Home logged={logged} />} />
              <Route
                path="/workspace/:idhoja"
                element={<Workspace userId={userId} />}
              />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" replace />} />
          )}

          {/* Página 404 */}
          <Route
            path="*"
            element={
              <Container style={{ marginLeft: "35%" }} fixed>
                <h1 style={{ color: "#42a5f5" }}>No hay nada aquí...</h1>
                <img src={""} alt="not-found" width={"50%"} />
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    </TitleProvider>
  );
}

export default App;
