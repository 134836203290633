import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fondo from "../assets/fondo.svg";
import alienaz from "../assets/alienaz.svg";
import { ReactComponent as AlienUser } from "../assets/alienUser.svg";
import { ReactComponent as TitleAlienaz } from "../assets/TitleAlienaz.svg";

export default function Home({ logged }) {
  const skillsBoxRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Handle the mouse down event to initiate drag
  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.clientX - skillsBoxRef.current.offsetLeft);
    setScrollLeft(skillsBoxRef.current.scrollLeft);
    skillsBoxRef.current.style.userSelect = "none"; // Disable text selection while dragging
  };

  // Handle the mouse move event to scroll horizontally
  const handleMouseMove = (e) => {
    if (!isMouseDown) return; // Only drag if the mouse is down
    const x = e.clientX - skillsBoxRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll faster by multiplying the movement
    skillsBoxRef.current.scrollLeft = scrollLeft - walk;
  };

  // Handle mouse up or leave event to stop the drag
  const handleMouseUp = () => {
    setIsMouseDown(false);
    skillsBoxRef.current.style.userSelect = "auto"; // Re-enable text selection when dragging stops
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
    skillsBoxRef.current.style.userSelect = "auto"; // Re-enable text selection when mouse leaves
  };

  const SkillCard = ({ title, description }) => (
    <Card sx={{ backgroundColor: "#ff2e63", color: "#f5f6fa" }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  );

  const skills = [
    {
      title: "Frontend",
      description:
        "React, Material UI (MUI), Bootstrap, jQuery, JavaScript (JS), HTML, CSS",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
  ];

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        backgroundImage: `url(${fondo})`,
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        backgroundColor: "#1f1f1f",
        backgroundSize: "contain",
        flexDirection: { xs: "column", sm: "column" },
      }}
    >
      {/* Header */}
      <div
        style={{
          width: "100%",
          maxHeight: "11vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          top: "0",
          backgroundColor: "rgba(2,2,2,0.52)",
        }}
      >
        <Box
          minWidth="none"
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            width: "45%",
            padding: "2%",
          }}
          gap={3}
        >
          <Typography
            variant="p"
            component="a"
            sx={{ color: "#f5f6fa", fontWeight: "lighter" }}
          >
            Acerca de mi
          </Typography>
          <Typography
            variant="p"
            component="a"
            sx={{ color: "#f5f6fa", fontWeight: "lighter" }}
          >
            Trabajos
          </Typography>
          <Typography
            variant="p"
            component="a"
            sx={{ color: "#f5f6fa", fontWeight: "lighter" }}
          >
            Contacto
          </Typography>
          <Typography
            variant="p"
            component="a"
            sx={{ color: "#f5f6fa", fontWeight: "lighter" }}
          >
            Notes
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "10%",
          }}
        >
          <img alt="Alien" src={alienaz} style={{ width: 90, height: 90 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "row",
            width: "45%",
            padding: "2%",
          }}
        >
          <Avatar sx={{ width: 45, height: 45, backgroundColor: "white" }}>
            <AlienUser style={{ color: "black" }} />
          </Avatar>
        </Box>
      </div>

      {/* Main Content */}
      <Box
        maxWidth="none"
        sx={{
          width: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          backgroundColor: "rgba(32,32,32,0.32)",
          gap: 2,
          padding: "5%",
        }}
      >
        <TitleAlienaz />
        <Typography variant="h3" sx={{ marginTop: "80px", color: "white" }}>
          Acerca de mi
        </Typography>
        <Typography
          variant="P"
          sx={{
            marginTop: "80px",
            color: "white",
            fontSize: "20px",
            width: "50%",
          }}
        >
          Hola, soy desarrollador web fullstack con casi 3 años de experiencia
          creando aplicaciones modernas y funcionales. Me especializo en React,
          Material UI y Firebase, siempre enfocado en brindar experiencias de
          usuario intuitivas y eficientes. Actualmente estoy ampliando mis
          conocimientos en TypeScript, AWS Lambda, MongoDB y Clean Architecture,
          con la meta de desarrollar soluciones cada vez más robustas y
          escalables.
        </Typography>
      </Box>

      {/* Skills Section */}
      <Box
        maxWidth="none"
        sx={{
          width: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "rgba(32,32,32,0.32)",
          padding: "5%",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginTop: "80px",
            color: "white",
            width: "100%",
            textAlign: "flex-start",
          }}
        >
          Habilidades
        </Typography>

        {/* Horizontal scroll container */}
        <Box
          ref={skillsBoxRef}
          onMouseDown={handleMouseDown} // Mouse down to start dragging
          onMouseMove={handleMouseMove} // Move to drag horizontally
          onMouseUp={handleMouseUp} // Mouse up to stop dragging
          onMouseLeave={handleMouseLeave} // Mouse leave to stop dragging
          sx={{
            display: "flex",
            gap: 2,
            maxWidth: "100%",
            padding: "20px 0",
            overflowX: "scroll",
            overflowY: "hidden", // Hide the vertical scrollbar
            cursor: "grab", // Cursor to indicate dragging
            scrollbarWidth: "none", // Hide the scrollbar
            msOverflowStyle: "none", // Hide scrollbar for IE/Edge
            marginTop: "100px",
          }}
        >
          {skills.map((skill, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                width: 250,
                height: 200,
                backgroundColor: "#ff2e63",
                color: "#fff",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <SkillCard {...skill} />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        maxWidth="none"
        sx={{
          width: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "rgba(32,32,32,0.32)",
          padding: "5%",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginTop: "80px",
            color: "white",
            width: "100%",
            textAlign: "flex-start",
          }}
        >
          Habilidades
        </Typography>

        {/* Horizontal scroll container */}
        <Box
          ref={skillsBoxRef}
          onMouseDown={handleMouseDown} // Mouse down to start dragging
          onMouseMove={handleMouseMove} // Move to drag horizontally
          onMouseUp={handleMouseUp} // Mouse up to stop dragging
          onMouseLeave={handleMouseLeave} // Mouse leave to stop dragging
          sx={{
            display: "flex",
            gap: 2,
            maxWidth: "100%",
            padding: "20px 0",
            overflowX: "scroll",
            overflowY: "hidden", // Hide the vertical scrollbar
            cursor: "grab", // Cursor to indicate dragging
            scrollbarWidth: "none", // Hide the scrollbar
            msOverflowStyle: "none", // Hide scrollbar for IE/Edge
            marginTop: "100px",
          }}
        >
          {skills.map((skill, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                width: 250,
                height: 200,
                backgroundColor: "#ff2e63",
                color: "#fff",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <SkillCard {...skill} />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
}
