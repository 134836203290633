import React, { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, CssBaseline } from "@mui/material";
import MyDrawer from "../components/my_drawer";
import { db } from "../database/config";
import { doc, onSnapshot } from "firebase/firestore";
import { useTitle } from "../context/TitleContext";
import { useSelector } from "react-redux";

const drawerWidth = 280; // Cambia este valor según sea necesario
const collapsedDrawerWidth = 30; // Ancho cuando el drawer está contraído
const appBarHeight = 45;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true); // Estado para controlar el drawer en pantallas grandes
  const { title, setTitle } = useTitle();
  const location = useLocation();
  const userId = useSelector((state) => state.auth.idUser);
  const [availableSheets, setAvailableSheets] = useState([]);

  useEffect(() => {
    const pathTitles = {
      "/": "Inicio",
      "/workSpace": "Espacio de trabajo",
    };
    setTitle(pathTitles[location.pathname] || "Default Title");
  }, [location.pathname, setTitle]);

  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "workspaces", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAvailableSheets(data.hojas || []);
      } else {
        setAvailableSheets([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Controlar el estado del drawer en pantallas grandes
  const handleDrawerToggleDesktop = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        scrollbarWidth: "none",
        backgroundColor: "#1f1f1f",
      }}
    >
      <CssBaseline />
      <MyDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen}
        handleDrawerToggleDesktop={handleDrawerToggleDesktop}
        availableSheets={availableSheets} // Pasar availableSheets como prop a MyDrawer
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: { xs: 0, sm: 2 },
          pl: { xs: 0, sm: 2, lg: 4 },
          pr: { xs: 0, sm: 2, lg: 7 },
          m: 0,
          mt: `${appBarHeight}px`,
          ml: { lg: `${drawerOpen ? drawerWidth : collapsedDrawerWidth}px` }, // Ajusta el margen según el estado
          width: {
            xs: `calc(100vw - ${
              drawerOpen ? drawerWidth : collapsedDrawerWidth
            }px)`,
          },
          height: { xs: `calc(100vh - ${appBarHeight}px)` },
          overflow: "auto",
          scrollbarWidth: "none",
          zIndex: 999,
        }}
      >
        <Outlet context={{ availableSheets }} />{" "}
        {/* Pasar availableSheets como contexto al Outlet */}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Layout;
