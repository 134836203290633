import React, { useEffect, useState, useCallback } from "react";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { useCreateBlockNote } from "@blocknote/react";
import {
  saveUserSheet,
  subscribeToUserSheets,
  deleteUserSheet,
} from "../database/workSpace";
import { compressImage, convertToBase64 } from "../utils/functions";
import debounce from "lodash.debounce";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";

const cleanContent = (content) => {
  return content;
};

const extractTitleFromContent = (content) => {
  if (content && content.content && Array.isArray(content.content)) {
    for (const block of content.content) {
      if (block.type === "text" && block.text) {
        return block.text.substring(0, 15);
      }
      if (block.content) {
        const title = extractTitleFromContent(block);
        if (title) {
          return title.substring(0, 15);
        }
      }
    }
  }
  return "Sin título";
};

const Workspace = ({ userId }) => {
  const { idhoja } = useParams();
  const [sheetId, setSheetId] = useState(idhoja || "sheet-1");
  const [title, setTitle] = useState("Sin título");
  const [image, setImage] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const { availableSheets } = useOutletContext();

  const uploadFile = useCallback(async (file) => {
    try {
      const compressedBlob = await compressImage(file);
      const base64Image = await convertToBase64(compressedBlob);
      return base64Image;
    } catch (error) {
      console.error("Error al cargar el archivo:", error);
      throw error;
    }
  }, []);

  const editor = useCreateBlockNote({
    tools: ["Titulo", "Texto", "bold", "italic", "Imagen", "Lista", "Codigo"],
    extensions: [],
    uploadFile,
  });

  const debouncedSave = useCallback(
    debounce((userId, sheetId, { title, content, image }) => {
      saveUserSheet(userId, sheetId, { title, content, image });
    }, 1000),
    []
  );

  const handleDeleteSheet = async () => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás deshacer esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: "Eliminando hoja",
          text: "Por favor espera...",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        await deleteUserSheet(userId, sheetId);

        if (availableSheets.length > 1) {
          const newSheet = availableSheets.find(
            (sheet) => sheet.idhoja !== sheetId
          );
          if (newSheet) {
            navigate(`/workSpace/${newSheet.idhoja}`);
          }
        } else {
          navigate(`/workSpace`);
        }

        Swal.close();
      } catch (error) {
        Swal.fire("Error", "Hubo un problema al eliminar la hoja", "error");
        console.error("Error al eliminar la hoja:", error);
      }
    }
  };

  const handlePaste = async (event) => {
    const clipboardData = event.clipboardData;
    const items = clipboardData.items;

    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file) {
          try {
            const base64Image = await uploadFile(file);

            // Evita crear una nueva hoja al pegar la imagen
            setImage(base64Image);

            if (userId && sheetId) {
              const content = editor._tiptapEditor.getJSON();
              await saveUserSheet(userId, sheetId, {
                title,
                content,
                image: base64Image,
              });
            }

            // Inserta la imagen en el contenido del editor
            editor._tiptapEditor.commands.insertContent({
              type: "image",
              attrs: { src: base64Image, alt: file.name },
            });
          } catch (error) {
            console.error("Error al procesar la imagen:", error);
          }
        }
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (editor && editor._tiptapEditor && editor._tiptapEditor.view) {
      const editorElement = editor._tiptapEditor.view.dom;
      editorElement.addEventListener("paste", handlePaste);
      return () => {
        editorElement.removeEventListener("paste", handlePaste);
      };
    }
  }, [editor]);

  useEffect(() => {
    if (editor && editor._tiptapEditor) {
      const handleChange = () => {
        try {
          const fullContent = editor._tiptapEditor.getJSON();
          const cleanedContent = cleanContent(fullContent);
          const newTitle = extractTitleFromContent(cleanedContent);

          if (newTitle !== title) {
            setTitle(newTitle);
          }

          if (userId && sheetId) {
            debouncedSave(userId, sheetId, {
              title: newTitle,
              content: cleanedContent,
              image,
            });
          }
        } catch (error) {
          console.error("Error al obtener el contenido del editor:", error);
        }
      };

      editor._tiptapEditor.on("update", handleChange);
      return () => {
        editor._tiptapEditor.off("update", handleChange);
      };
    }
  }, [editor, userId, sheetId, debouncedSave, title, image]);

  useEffect(() => {
    if (userId) {
      const unsubscribe = subscribeToUserSheets(userId, (data) => {
        if (data && data.hojas) {
          const sheet = data.hojas.find((hoja) => hoja.idhoja === sheetId);
          if (sheet) {
            try {
              const currentContent = editor._tiptapEditor.getJSON();

              // Solo actualiza si el contenido es diferente
              if (
                JSON.stringify(currentContent) !== JSON.stringify(sheet.content)
              ) {
                editor._tiptapEditor.commands.setContent(sheet.content);
                setTitle(sheet.title);
                setImage(sheet.image || null);
              }
            } catch (error) {
              console.error(
                "Error al actualizar el contenido del editor:",
                error
              );
            }
          }
        }
      });
      return () => unsubscribe();
    }
  }, [userId, editor, sheetId]);

  useEffect(() => {
    if (idhoja && idhoja !== sheetId) {
      setSheetId(idhoja);
    }
  }, [idhoja, sheetId]);

  return (
    <div style={{ padding: "10px", marginBottom: "250px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleDeleteSheet}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1000,
        }}
      >
        Eliminar hoja
      </Button>
      {image && (
        <img
          src={image}
          alt="Imagen subida"
          style={{ maxWidth: "100%", marginBottom: "10px" }}
        />
      )}
      <BlockNoteView editor={editor}  />
    </div>
  );
};

export default Workspace;
